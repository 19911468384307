.ReactTable {
  border: none;
}

.ReactTable .rt-table {
  border-radius: 0 0 6px 6px;
  background: #fff;
}
.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-thead .rt-th {
  border-right: none;
}

.ReactTable .rt-thead .rt-th {
  line-height: 30px;
}

.ReactTable .rt-thead .rt-tr,
.ReactTable .rt-tbody .rt-tr {
  text-align: left;
  align-items: center;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
}
.rt-thead {
  box-shadow: none;
  border-bottom: 1px solid rgb(217, 217, 217, 0.3);
  font-size: 14px;
  color: #88909d;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid rgb(217, 217, 217, 0.3);
}
.rt-tbody {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #424b68;
  padding-bottom: 20px;
}
.ReactTable .-pagination {
  box-shadow: none;
  border-top: none;
}
.rt-table {
  margin-bottom: 20px;
}

.ReactTable .-pagination .-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background: #424b68;
  border-radius: 17px;
  width: inherit;
  height: 34px;
  padding: 0 10px;
}
.-pagination .-btn[disabled] {
  color: #88909d;
  border: none;
  background: none;
}

.ReactTable .rt-td:first-child,
.ReactTable .rt-th:first-child {
  padding-left: 32px;
}
.ReactTable .rt-td:last-child,
.ReactTable .rt-th:last-child {
  padding-right: 32px;
}
.pagination-bottom {
  color: #88909d;
}
